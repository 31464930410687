import { StaticImage } from "gatsby-plugin-image"
import React, { ReactNode } from "react"
import styled from "styled-components"
import { BaseContainer } from "../containers"

import { GridContainer } from "../grid"
import { H2 } from "../text"

const HeroContainer = styled(GridContainer)`
  grid-column: 1 / span 12;
  color: ${({ theme }) => theme.colors.beukelaarGreen};
  background: ${({ theme }) => theme.colors.grey};
  margin-bottom: 2rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding-bottom: 8rem;
  }
`

const ContentContainer = styled.div`
  grid-column: 1 / span 12;
  padding: 2rem 1rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 4rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    grid-column: 2 / span 7;
    padding: 4rem 0;
  }
`

const ImageContainer = styled(BaseContainer)`
  grid-column: 1 / span 12;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-top: -10rem;
    grid-column: 2 / span 10;
  }
`

const Title = styled(H2)`
  margin-bottom: 2rem;
`

interface PhotoHeroProps {
  title: string
  subtitle: string
  image?: ReactNode
  className?: string
}

const PhotoHero: React.FC<PhotoHeroProps> = ({
  title,
  subtitle,
  image,
  className,
}) => (
  <>
    <HeroContainer className={className}>
      <ContentContainer>
        <Title>{title}</Title>
        <p>{subtitle}</p>
      </ContentContainer>
    </HeroContainer>
    <ImageContainer>
      {image ? (
        image
      ) : (
        <StaticImage
          src="../../images/organisatie.jpg"
          alt="Organisatie"
          placeholder="blurred"
        />
      )}
    </ImageContainer>
  </>
)

export default PhotoHero
