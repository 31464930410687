import React, { ReactNode } from "react"
import styled from "styled-components"

const Container = styled.div`
  grid-column: 1 / span 12;
  margin-bottom: 2rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-bottom: 4rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    margin-bottom: 8rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    margin-bottom: 10rem;
  }
`

interface BaseContainerProps {
  children: ReactNode
  className?: string
}

const BaseContainer: React.FC<BaseContainerProps> = ({
  className,
  children,
}) => <Container className={className}>{children}</Container>

export default BaseContainer
