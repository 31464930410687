import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

import { PrimaryButton, SecondaryButton } from "../buttons"
import { H1, Subtitle } from "../text"

import AnimatedHeroSVG from "../../svg/dbg-logo-symbol-animated.svg"
import MobileHeroSVG from "../../svg/dbg-hero-mobile.svg"

const HeroContainer = styled.div`
  grid-column: 1 / span 12;
  margin-bottom: 4rem;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-items: center;
  word-break: break-word;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-bottom: 8rem;
  }
`

const HeroInnerContainer = styled.div`
  grid-column: 1 / span 12;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    grid-column: 1 / span 6;
  }
`

const HeroButtonContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;

  > * + * {
    margin: 1rem 0 0 0;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    flex-direction: row;
    align-items: center;

    > * + * {
      margin: 0 0 0 1rem;
    }
  }
`

const MobileLogoSymbolContainer = styled.div`
  grid-column: 1 / span 12;
  margin-bottom: 1rem;

  svg {
    width: 100%;
    height: 100%;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    display: none;
  }
`

const AnimatedLogoSymbolContainer = styled.div`
  display: none;

  svg {
    width: 100%;
    height: 100%;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    grid-column: 8 / span 5;
    display: flex;
    justify-content: flex-end;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    svg {
      width: 90%;
      height: 90%;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    svg {
      width: 80%;
      height: 80%;
    }
  }
`

const Title = styled(H1)`
  margin-bottom: 2rem;
`

const HeroButton = styled(PrimaryButton)``

interface MainHeroProps {
  title: string
  subtitle: string
  className?: string
}

const MainHero: React.FC<MainHeroProps> = ({ className, title, subtitle }) => (
  <HeroContainer className={className}>
    <MobileLogoSymbolContainer>
      <MobileHeroSVG />
    </MobileLogoSymbolContainer>
    <HeroInnerContainer>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <HeroButtonContainer>
        <Link to="/contact/">
          <HeroButton>Neem contact op</HeroButton>
        </Link>
        <Link to="/diensten/">
          <SecondaryButton>Bekijk onze diensten</SecondaryButton>
        </Link>
      </HeroButtonContainer>
    </HeroInnerContainer>
    <AnimatedLogoSymbolContainer>
      <AnimatedHeroSVG />
    </AnimatedLogoSymbolContainer>
  </HeroContainer>
)

export default MainHero
