import React from "react"
import styled from "styled-components"

import { H1, Subtitle } from "../text"

interface StyledHeroContainerProps {
  $isOnDark: boolean
}

const HeroContainer = styled.div<StyledHeroContainerProps>`
  margin-bottom: 4rem;
  grid-column: 1 / span 12;
  word-break: break-word;

  color: ${props =>
    props.$isOnDark
      ? ({ theme }) => theme.colors.white
      : ({ theme }) => theme.colors.offBlack};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    grid-column: 3 / span 8;
  }
`

const Title = styled(H1)`
  margin-bottom: 2rem;
`

interface SecondaryHeroProps {
  title: string
  subtitle?: string
  className?: string
  isOnDark?: boolean
}

const SecondaryHero: React.FC<SecondaryHeroProps> = ({
  title,
  subtitle,
  isOnDark = false,
  className,
}) => (
  <HeroContainer $isOnDark={isOnDark} className={className}>
    <Title>{title}</Title>
    {subtitle && <Subtitle>{subtitle}</Subtitle>}
  </HeroContainer>
)

export default SecondaryHero
