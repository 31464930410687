import React from "react"
import styled from "styled-components"

import { H1 } from "../text"

const Title = styled(H1)`
  grid-column: 1 / span 12;
  margin-bottom: 2rem;
  word-break: break-word;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-bottom: 4rem;
  }
`
interface SimpleTitleProps {
  className?: string
}

const SimpleTitle: React.FC<SimpleTitleProps> = ({ className, children }) => (
  <Title className={className}>{children}</Title>
)

export default SimpleTitle
