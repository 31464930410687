import React, { ReactNode } from "react"
import styled from "styled-components"
import BaseButton from "./base-button"

interface StyledSecondaryButtonProps {
  $isOnDark: boolean
}

const Button = styled(BaseButton)<StyledSecondaryButtonProps>`
  border: 4px solid
    ${props =>
      props.$isOnDark
        ? ({ theme }) => theme.colors.white
        : ({ theme }) => theme.colors.offBlack};

  color: ${props =>
    props.$isOnDark
      ? ({ theme }) => theme.colors.white
      : ({ theme }) => theme.colors.offBlack};

  svg {
    fill: ${props =>
      props.$isOnDark
        ? ({ theme }) => theme.colors.white
        : ({ theme }) => theme.colors.offBlack};
  }

  &:hover,
  &:focus {
    color: ${props =>
      props.$isOnDark
        ? ({ theme }) => theme.colors.beukelaarGreenLight
        : ({ theme }) => theme.colors.beukelaarGreen};

    border-color: ${props =>
      props.$isOnDark
        ? ({ theme }) => theme.colors.beukelaarGreenLight
        : ({ theme }) => theme.colors.beukelaarGreen};

    svg {
      fill: ${props =>
        props.$isOnDark
          ? ({ theme }) => theme.colors.beukelaarGreenLight
          : ({ theme }) => theme.colors.beukelaarGreen};
    }
  }
`

interface SecondaryButtonProps {
  children: ReactNode
  type?: "submit" | "reset" | "button"
  isOnDark?: boolean
  disabled?: boolean
  onClick?: (ev: React.MouseEvent<HTMLButtonElement>) => void
  className?: string
}

const SecondaryButton: React.FC<SecondaryButtonProps> = ({
  children,
  type,
  isOnDark = false,
  disabled,
  onClick,
  className,
}) => (
  <Button
    $isOnDark={isOnDark}
    disabled={disabled}
    type={type}
    onClick={onClick}
    className={className}
  >
    {children}
  </Button>
)

export default SecondaryButton
