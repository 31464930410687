import React from "react"
import styled from "styled-components"
import { PageProps } from "gatsby"

import SEO from "../components/seo"

import { GridContainer } from "../components/grid"
import { SecondaryHero } from "../components/heros"
import { PrimaryLink } from "../components/links"
import { BaseContainer } from "../components/containers"

const LinksContainer = styled(BaseContainer)`
  grid-column: 1 / span 12;
  display: grid;
  gap: 1rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 2rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    grid-column: 3 / span 8;
  }
`

const NotFoundPage: React.FC<PageProps> = () => (
  <>
    <SEO title="404" />
    <GridContainer>
      <SecondaryHero
        isOnDark
        title="404"
        subtitle="Kun je door de bomen het bos niet meer zien?"
      ></SecondaryHero>
      <LinksContainer>
        <PrimaryLink isOnDark to="/over-ons/">
          Over Ons
        </PrimaryLink>
        <PrimaryLink isOnDark to="/diensten/">
          Diensten
        </PrimaryLink>
        <PrimaryLink isOnDark to="/contact/">
          Contact
        </PrimaryLink>
        <PrimaryLink isOnDark href="https://academie.debeukelaargroep.nl/">
          Online Academie
        </PrimaryLink>
      </LinksContainer>
    </GridContainer>
  </>
)

export default NotFoundPage
