import React, { ReactNode } from "react"
import styled from "styled-components"
import BaseButton from "./base-button"

const Button = styled(BaseButton)`
  background: ${({ theme }) => theme.colors.beukelaarGreen};
  color: ${({ theme }) => theme.colors.white};

  svg {
    fill: ${({ theme }) => theme.colors.white};
  }

  &:hover,
  &:focus {
    background: ${({ theme }) => theme.colors.beukelaarGreenLight};
  }
`

interface PrimaryButtonProps {
  children: ReactNode
  onClick?: (ev: React.MouseEvent<HTMLButtonElement>) => void
  disabled?: boolean
  type?: "submit" | "reset" | "button"
  className?: string
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  children,
  type,
  disabled,
  onClick,
  className,
}) => (
  <Button
    disabled={disabled}
    className={className}
    type={type}
    onClick={onClick}
  >
    {children}
  </Button>
)

export default PrimaryButton
