import React from "react"
import styled from "styled-components"

import ArrowRight from "../../svg/arrow-right.svg"

const Button = styled.button`
  display: flex;
  align-items: center;
  font-family: inherit;
  min-height: 48px;
  padding: 0 1rem;
  text-align: center;
  border: none;
  margin: 0;
  font-weight: 900;
  font-size: 16px;
  line-height: 28px;
  cursor: pointer;
  text-transform: uppercase;
  background: inherit;

  svg {
    margin-left: 1rem;
    fill: ${({ theme }) => theme.colors.offBlack};
    transition: ${({ theme }) => theme.transitions.arrowOnHover};
  }

  &:hover,
  &:focus {
    svg {
      transform: translateX(4px);
    }
  }

  &:disabled {
    background: ${({ theme }) => theme.colors.beukelaarGreenLight};
  }
`

interface BaseButtonProps {
  type?: "submit" | "reset" | "button"
  disabled?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  className?: string
}

const BaseButton: React.FC<BaseButtonProps> = ({
  children,
  type = "button",
  disabled = false,
  onClick,
  className,
}) => (
  <Button
    disabled={disabled}
    onClick={onClick}
    type={type}
    className={className}
  >
    {children}
    <ArrowRight />
  </Button>
)

export default BaseButton
